body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTextField-root {
  width: 100%;
}

.MuiCardHeader-title {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.MuiFormControl-root {
  width: 100%;
}

.dnd-highlight {
  box-shadow: 0 0 5px rgba(55, 56, 56, 0.5);
  padding: 2px;
  margin: 1px;
  background-color: #f2f2f2;
}

.dnd-highlight-radio {
  box-shadow: 0 0 5px rgba(55, 56, 56, 0.5);
  padding: 2px;
  margin: 1px;
  background-color: #f2f2f2;
  height: auto !important;
}

.dnd-highlight > button {
  opacity: 1 !important;
  transform: translateY(-30px);
}

.revert-font {
  font-size: revert !important;
}

.bold {
  font-weight: 600 !important;
}

/* NAV wizard */
.nav {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.nav-active {
  color: red;
  opacity: 1;
  text-shadow: 0 0px 8px;
}

.hidden {
  display: none !important;
}

.initial {
  position: initial !important;
  height: 35px !important;
}
